/**
 * @author Kyles Light
 * @email kuilin@yangqianguan.com
 * @date 2018-07-14 11:24:30.934
 * @desc generated by yqg-cli@0.2.4
 */

import Resource from 'moneycan/common/resource';

export default {
    name: 'BorrowserList',

    title: 'Daftar Peminjam',

    data() {
        return {
            userInfo: null,
            borrowerList: [],
            fetched: false
        };
    },

    async mounted() {
        const vm = this;

        try {
            const {body: {body: userInfo}} = await Resource.fetchUserInfo();
            vm.userInfo = userInfo;

            const {body: {body: {borrowerList = []}}} = await Resource.fetchBorrowerList();
            vm.borrowerList = borrowerList.map(item => ({...item, message: ''}));
        } catch (err) {
            vm.userInfo = {};
            vm.borrowerList = [];
        }

        vm.fetched = true;
    },

    methods: {
        lend(item) {
            const vm = this;
            const {
                borrower: {mobile}
            } = item;

            Resource.acceptBorrower({mobileNumber: mobile}).then(() => {
                vm.$toast.success('Berhasil mendanai pinjaman');
                item.message = 'Dipinjamkan';
                vm.refreshUserInfo();
            }).catch(x => x);
        },

        deny(item) {
            const vm = this;
            const {
                borrower: {mobile}
            } = item;
            Resource.denyBorrower({mobileNumber: mobile}).then(() => {
                vm.$toast.success('Berhasil menolak pinjaman');
                item.message = 'Ditolak';
            }).catch(x => x);
        },

        logout() {
            const vm = this;
            Resource.logout().then(() => {
                vm.$router.replace({name: 'LenderSignIn'});
            }).catch(x => x);
        },

        async refreshUserInfo() {
            const vm = this;
            const {body: {body: userInfo}} = await Resource.fetchUserInfo();
            vm.userInfo = userInfo;
        }
    }
};
