var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fetched
    ? _c("div", { staticClass: "borrowser-list" }, [
        _c("div", { staticClass: "top-card" }, [
          _c("h3", [
            _vm._v(
              "Halo, " + _vm._s(_vm.userInfo.individualName) + "\n            "
            ),
            _c(
              "button",
              { staticClass: "btn-logout", on: { click: _vm.logout } },
              [_vm._v("Keluar")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _c("span", { staticClass: "left" }, [_vm._v("Saldo saat ini")]),
            _vm._v(" "),
            _c("span", { staticClass: "right" }, [
              _vm._v("Rp " + _vm._s(_vm.userInfo.balance)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("h4", { staticClass: "borrower-title" }, [
              _vm._v("Daftar Peminjam"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.borrowerList, function (item, index) {
              return _c("div", { key: index, staticClass: "detail-card" }, [
                _c("div", { staticClass: "borrower" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "left" }, [_vm._v("Nama")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "right" }, [
                      _vm._v(_vm._s(item.borrower.name)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "left" }, [
                      _vm._v("Nomor Identitas"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "right" }, [
                      _vm._v(_vm._s(item.borrower.idNo)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "left" }, [
                      _vm._v("Jumlah pinjaman"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "right" }, [
                      _vm._v(_vm._s(item.borrower.amount)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "left" }, [_vm._v("Bunga")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "right" }, [
                      _vm._v(_vm._s(item.borrower.interest)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "left" }, [_vm._v("Durasi")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "right" }, [
                      _vm._v(_vm._s(item.borrower.term)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "feature" },
                  [
                    _c("h4", [_vm._v("Hasil Kredit")]),
                    _vm._v(" "),
                    _vm._l(item.feature, function (feature) {
                      return _c(
                        "div",
                        { key: feature.key, staticClass: "item" },
                        [
                          _c("div", { staticClass: "left" }, [
                            _vm._v(_vm._s(feature.key)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "right" }, [
                            _vm._v(_vm._s(feature.value)),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", [
                  !item.message
                    ? _c("div", { staticClass: "btn-wrap" }, [
                        _c(
                          "button",
                          {
                            staticClass: "refuse",
                            on: {
                              click: function ($event) {
                                return _vm.deny(item)
                              },
                            },
                          },
                          [_vm._v("Menolak")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "accept",
                            on: {
                              click: function ($event) {
                                return _vm.lend(item)
                              },
                            },
                          },
                          [_vm._v("Meminjamkan")]
                        ),
                      ])
                    : _c("div", [
                        _c("p", { staticClass: "center messgae" }, [
                          _vm._v(_vm._s(item.message)),
                        ]),
                      ]),
                ]),
              ])
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }